body{
  background-color: #1f1f1f;
  overflow-x: hidden;
  width: 100%;
}

.periodo-eleitoral-title{
  margin-top: 20%;
  font-size: 30px;
}
.periodo-eleitoral-text{
  margin-bottom: 20%;
  font-size: 15px;
  width: 95%;
}


body > iframe{
  z-index: -1 !important;
}

.App {
  text-align: center;
  color: #fff;
  margin-top: -21px;
  padding: 10px;
  font-size: 8px;
  width: 100%;
}
.app-login{
  overflow: hidden;
}


.App-header {
  font-size: 20px;
  min-height: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: -1000;
}
.App-logo{
  margin-top: -100px;
  width: 220px;
  transition: 0.7s;
  cursor: pointer;
}
.logo-item{
  width: 250px;
  transition: 0.7s;
  position: absolute;
  left: 0px;
  top: 0;
  cursor: pointer;
}
.logo-login{
  margin-top: 20px;
  margin-bottom: 20px;
  width: 220px;
  transition: 0.7s;
  cursor: pointer;
}
.btnlogout{
  text-align: initial;
  font-size: 15px;
  position: absolute;
  top: 35px;
  left: 88%;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}




/* Menu Navigation ------------------------------------------------------------------------------------ */
.main-menu{
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0px;
  left: 0;
  display: inline-flex;
  align-items: center;
  z-index: 10;
  color: white;
  background-color: #353535;
}
.logo-menu{
  max-height: 90px;
  width: 200px;
  position: absolute;
  left: 30px;
  cursor: pointer;
  color: white;
}
.menu-items{
  margin-top: 10px;
  margin-left: 250px;
}
.itemsMenu{
  color: rgba(255, 255, 255, 0.308);
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
  text-decoration: none;
  transition: 0.4s all;
  cursor: pointer;
}
.itemsMenu:hover{
  color: white;
}
.left-menu{
  color: rgba(255, 255, 255, 1);
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
  text-decoration: none;
  transition: 0.4s all;
  margin-left: 850px;
}
.search-icon{
  margin-right: -2px;
  position: relative;
  top: 2px;
}
.left-menu-item{
  color: rgba(255, 255, 255, 1);
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
  text-decoration: none;
  transition: 0.4s all;
  cursor: pointer;
}
.notification{
  margin-left: 10px;
  cursor: pointer;
}
.profile-items{
  max-height: 50px;
  width: 50px;
  border-radius: 9px;
  position: absolute;
  top: 20px;
  left: 93%;
  transition: 0.4s all;
}
.profile-icon{
  font-size: 35px;
  max-height: 50px;
  width: 50px;
  cursor: pointer;
  transition: 0.2s all;
  border-radius: 9px;
  margin-top: 10px;
}
.profile-items:hover {
  border-radius: 9px 9px 0 0;
}

.profile-items:hover .dropMenu{
  display: block;
  transition: 0.4s all;
}
.dropMenu{
  display: none;
  position: relative;
  left: -50px;
  width: 100px;
  transition: 0.4s all;
}
.dropMenu-items{
  margin-top: 0px;
  list-style: none;
  border-radius: 5px;
}
.dropMenu-btn-li{
  margin-left: -39px;
  padding-top: 7px;
  background-color: #1A1A1A;
  align-content: center;
  width: 100px;
  height: 30px;
  cursor: pointer;
  transition: 0.4s all;
}
.dropMenu-items > li:hover{
  background-color: #5c5c5c;
}
.dropMenu-btn-li1{
  border-radius: 10px 0 0 0;
}
.dropMenu-btn-li2{
  border-radius: 0 0 10px 10px;
}
.dropMenu-btn{
  font-size: 15px;
  width: 100px;
  color: #fff;
  text-decoration: none;
  width: 100px;
  height: 20px;
}
.Navmenu{
  width: 100%;
  background: transparent;
  margin: 0 auto;
  margin-top: 350px;
  font-size: 20px;
  z-index: 100;
  display: block;
  align-items: center;
  justify-items: center;
  margin-bottom: -30px;

}
.Navmenu > ul{
  list-style: none;
  position: relative;
  top: -65px;
  widows: 100%;
  height: 80px;
}
.Navmenu > ul > li{
  display: block;
  color: #7f32ee;
  display: inline;
  flex-direction: row;
  margin: 5px;
  cursor: pointer;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;

}
.Navlogo{
  margin-top: -250px;
  align-items: center;
  justify-items: center;
}
.titleTxt{
  font-size: 25px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.subTitleTxt{
  font-size: 35px;
  margin-top: -300px;
}
.logo{
  width: 100px;
  margin-top: 5px;
  position: relative;
  left: 15px;
}
.logoHome{
  width: 550px;
  margin-top: -200px;
  margin-bottom: 100px;
  cursor: pointer;
}

.btnHome{
  display: inline-block;
  font-size: 20px;
  transition: 0.4s;
  width: 180px;
  height: 110px;
  background: white;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
  padding-top: 20px;
  margin-right: -10px;
}
.btnHome1{
  border-radius: 10px 0px 0px 10px;
  border-right: 1px dashed silver;
}
.btnHome2{
  border-radius: 0px 0px 0px 0px;
  border-right: 1px dashed silver;
}
.btnHome3{
  border-radius: 0px 10px 10px 0px;
}
.btnHome:hover{
  background-color: rgb(235, 235, 235);
  color: #7f32ee;
}
.btnCadastro:hover{
  background: #3b378a;
  border: none;
}


/* ----------------------------------------------------------------------- */





/* Home Page -----------------------------------------------------------------------------------*/



/* Hero --------------------------------------------------------------------------------------- */

.hero{
  width: 100%;
  text-align: right;
}
.backgroundHero{
  width: 103%;
  height: 690px;
  margin-left: -50px !important;
  margin: 0 auto;
  border: none;
  object-fit: cover;
}
.heroPg{
  margin-bottom: -440px;
  height: 720px;
  padding-top: 0px;
}
.heroLogin{
  height: 50%;
}
.titleHero{
  margin-top: -414px;
  font-size: 70px;
}
.subtilteHero{
  font-size: 25px;
  margin-top: -60px;
}
.detalsHero{
  font-size: 18px;
  margin-top: -20px;
  color: silver;
}
.btnHero{
  color: #35a4ff;
  margin-right: 40px;
  font-size: 20px;
  text-decoration: none;
}
.btnHero:hover{
  text-decoration: underline;
}

.curso-destaque{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 80px;
  top: 250px;
  text-align: left;
}
.title-curso-destaque{
  font-family: Open Sans, sans-serif;
  font-size: 50px;
  line-height: 47px;
  width: 40%;
  color: rgb(255, 255, 255);
  margin-bottom: -10px;
}
.logo-curso-destaque{
  max-height: 100px;
  margin-left: -20px;
}
.desc-curso-destaque{
  font-size: 16px;
  width: 40%;
  color: rgb(182, 182, 182);
  margin-bottom: 30px;
}
.btn-curso-destaque{
  background-color: #FF6200;
  font-size: 13px;
  font-weight: 600;
  width: 12%;
  height: 40px;
  border: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s all;
}
.btn-curso-destaque:hover{
  background-color: #c44d03;
}


/* Seçoes ----------------------------------------------------------------------------- */

/* Lançamentos */

.backgroundLaunch{
  width: 95%;
  border: none;
  object-fit: contain;
  display: inline-block;
  margin-top: 50px;
}

.videoLiveInicio{
  width: 95%;
  height: 500px;
}

.videoLiveInicio>div>div>iframe{
  width: 100%;
  height: 90%;
  margin-top: 15px;
}


.curso-lancamento{
  text-align: left;
  position: relative;
  left: 31px;
  top: 120px;
  width: 95%;
  margin: 10px;
}
.title-curso-lancamento{
  font-family: Open Sans, sans-serif;
  font-size: 30px;
  line-height: 47px;
  width: 40%;
  color: rgb(182, 182, 182);
  margin-bottom: -10px;
  display: none;
}
.desc-curso-lancamento{
  font-size: 16px;
  width: 40%;
  color: rgb(182, 182, 182);
  margin-bottom: 30px;
  display: none;
}
.btn-curso-lancamento{
  background-color: #FF6200;
  font-size: 13px;
  font-weight: 500px;
  width: 12%;
  height: 40px;
  border: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s all;
}
.btn-curso-lancamento:hover{
  background-color: #c94e01;
}

.btn-curso-lancamento2{
  background-color: transparent;
  font-size: 13px;
  font-weight: 600;
  width: 12%;
  height: 40px;
  border: 1px solid #FF6200;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s all;
  margin-left: 20px;
}
.btn-curso-lancamento2:hover{
  background-color: #ff620079;
}

.watchVideo{
  width: 100% !important;
  height: 750px !important;
}

#facebook-player-dh8o5{
  width: 100%;
  height: 65% !important;
}


/* ----------------------------------- Cursos ------------------------------------------------ */
.courses{
  width: 100%;
  margin: 50px auto;
  margin-left: -3%;
}
.course-featured{
  width: 80%;
  display: inline-flex;
  margin-top: 50px;
}
.logoItem{
  width: 10% !important;
}
.img-course{
  width: 100%;
  margin-left: -20px;
  margin-right: 20px;
}
.img-course > img {
  width: 100%;
  border-radius: 10px;
  background-size: 100%;
}
.txt-course{
  margin-left: 30px;
  text-align: left;
}
.txt-course > h1{
  font-family: Open Sans, sans-serif;
  font-size: 45px;
  font-weight: 600;
}
.txt-course > h2{
  margin-top: -30px;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}
.txt-course > p{
  text-align: justify;
  margin-top: 10px;
  font-size: 10px;
  width: 100%;
  color: #A9A9A9;
}
.searchIcon{
  position: absolute;
  margin: 10px 0 0 7px;
  font-size: 15px;
  color:  rgba(159, 159, 159, 0.63);
}
.searchItem{
  width: 250px;
  height: 35px;
  padding: 0 0 0 40px;
  background-color: rgba(159, 159, 159, 0.23);
  border-radius: 5px;
  border: none;
}
.ficha-tecnica{
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 100px;
  text-align: left;
  display: inline-flex;
}
.titleFichaTecnica{
  font-size: 25px;
  font-weight: 100;
  position: relative;
  top: -40px;
  left: -100px;
}
.underline{
  background-color: white;
  width: 20%;
  height: 1px;
  position: relative;
  top: -40px;
  left: -100px;
}


.ficha-tecnica-left{
  width: 30%;
  margin-left: 20%;
}

.subTitleFichaTecnica{
  font-size: 25px;
}
.txtFichaTecnica{
  font-size: 12px;
  color: #696969;
}

.ficha-tecnica-rigth{
  margin-top: 80px;
  margin-left: 20%;
  width: 40%;
}

.btn-curso-destaque-2{
  padding: 10px;
  font-size: 13px;
  height: 40px;
  border: 1px solid #FF6200;
  color: #fff;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  transition: 0.4s all;
}
.btn-curso-destaque-2:hover{
  background-color: #ff620079;
}

.lives{
  margin-top: 100px;
  flex: 1;
  width: 100%;
  margin-left: -3%;
}
.section2 > h1{
  list-style-type: disc;
}
.sectionColor{
  background-color: #000;
  margin-left: -20px;
  flex: 1;
  width: 105%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.sectionColor > h1{
  color: white;
}
.divTitleSection{
  margin-left: 90px;
  margin-top: 90px;
  display: inline-flex;
  width: 100%;
  align-items: flex-start;
}
.item-separator{
  width: 10px;
  height: 70px;
  border-radius: 11px;
  background: #ff6200;
}
.titleSection{
  margin-left: 20px;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
}
.titleSection > img {
  margin-right: 15px;
  margin-bottom: -10px;
}
.newsSection{
  font-size: 16px;
  margin-top: 80px;
  margin-left: -52px;
  margin-bottom: -0px;
}

.txtSection{
  width: 40%;
  font-size: 40px;
  margin-right: 120px;
  margin-top: 200px;
  text-align: initial;
}
.imgSection{
  width: 40%;
  margin-top: 138px;
  animation: animateImg 2s linear infinite;
}

/* Aulas ----------------------------------------------------------------------- */

.box-class{
  width: 100%;
  justify-content: left;
  align-items: flex-start;
  align-content: flex-start;
  display: inline-flex;
}
.boxClass-text{
  width: 50%;
  margin-top: -40px;
  margin-left: 40px;
  text-align: left;
}
.boxClass-text > img {
  width: 100%;
  margin-bottom: 30px;
  margin-left: -25px;
}
.class-section{
  width: 100%;
  padding-top: 10px;
}
.class-list{
  width: 100%;
  margin-left: -5%;
  margin-top: 100px;
}
.class{
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
  margin-top: 12px;
  transition: 0.4s all;
  text-align: left;
}
.class:hover{
  transform: scale(1.05) ;
}

.class > img{
  width: 265px;
  height: 160px;
  object-fit: cover;
}


.iconPlay{
  position: relative;
  top: -5px;
  left: -30px;
  font-size: 25px;
  color: rgb(168, 168, 168, 0.6);
}

.titleCard{
  margin-top: 0px;
  font-size: 12px;
  font-weight: 600;
  max-width: 33ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.courseCard{
  color: #a0a0a0;
  margin-top: -10px;
  font-size: 12px;
}


.titleCard:visited{
  color: #FF6200;
}

.box-video-aula{
  position: relative;
  top: 150px;
  width: 95%;
  margin: 0 auto;
  margin-top: 10px;
  text-align: left;
  display: inline-flex;
}
.box-video-aula > h1{
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
}
.video-play{
  margin-left: 10px;
  margin-right: 70px;
}
.players-video{
  z-index: 0;
  margin-top: 150px;
}

.desc-video{
  font-size: 15px;
  width: 40%;
  margin-top: -10px;
  text-align: left;
}

.desc-video > h1{
  margin-bottom: -5px;
}
.btn-downloads{
  padding: 10px;
  width: 200px;
  background-color: transparent;
  border: 1px solid #FF6200;
  color: white;
  border-radius: 7px;
  cursor: pointer;
  transition: 0.4s all;
}
.btn-downloads:hover{
  background-color: #ff620098;
}

/* Profile Pages ----------------------------------------------------------------------- */

.infos{
  width: 60%;
  height: 200px;
  display: inline-flex;
  margin-left: -40px;
  margin-top: 10px;
  margin-bottom: 100px;
  border-radius: 5px 5px 0 0 ;
}
.title-infos{
  text-align: left;
  width: 80%;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 300;
}
.left-infos{
  width: 50%;
  text-align: left;
  padding-left: 20px;
  display: block;
  flex: 1;
}
.left-infos > h1{
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  display: inline-flex;
  border-bottom: 1px solid #bababa;
  text-align: left;
}
.left-infos > h1 > h2{
  font-size: 24px;
  font-weight: 400;
  margin-top: -10px;
  margin-left: 30px;
  text-align: left;
}
.left-infos > h1 > p{
  font-size: 15px;
  margin-top: 0px;
  margin-left: 50px;
  text-align: left;
}
.left-infos > h2{
  font-size: 12px;
  color: #848484;
  text-transform: uppercase;
}
.left-infos > p{
  margin-top: -10px;
  font-size: 12px;
  color: #848484;
}
.rigth-infos{
  width: 50%;
  margin-left: 50px;
  padding-right: 20px;
  display: block;
  text-align: left;
}
.rigth-infos > p{
  margin-bottom: -10px;
  font-size: 12px;
  color: #848484;
}
.btn-infos{
  color: #fff;
}
.btnDash{
  width: 40% !important;
}
.btn-cancel{
  width: 200px;
  height: 40px;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.1);
  border: 1px solid #FFf;
  color: #fff;
  transition: 0.4s all;
  cursor: pointer;
}
.btn-cancel:hover{
  background-color: #fff;
  color: #000;
}


/* Animações -------------------------------------------------------------------------------------------------- */

@keyframes animateImg {
  0%{transform: scale(1)}
  50%{transform: scale(1.02)}
  100%{transform: scale(1)}
}
.txtSection > p{
  margin-top: -40px;
  font-size: 20px;
}
.txtRight{
  float: right;
}
.txtLeft{
  float: left;
  text-align: end;
  margin-left: 80px;
}

.txtSchool{
  margin-top: 100px;
}
.imgSchool{
  margin-top: 20px;
}

.txt3{
  margin-top: 104px;
}


/* ----------------------------------------------------------------------- */

/* Icone da Página e Usuário ---------------------------------------------------------------*/
#iconeUser{
  padding: 0;
}
.iconePage{
  position: absolute;
  flex-direction: row !important;
  margin-top: 30px;
  margin-bottom: 0;
  flex: 1;
  align-items: center;
  margin-left: 60px;
}
.iconePageHome{
  position: absolute;
  flex-direction: row !important;
  margin-top: 30px;
  margin-bottom: 0;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  margin-left: 80px;
}
.imgIconePageProf{
  width: 60px;
  height: 60px;
  float: left;
  flex-direction: row;
  margin-top: 5px;
  margin-right: 10px;
  border-radius: 100%;
  border: 3px solid #fff;
  background-color: #6508bb;
}
.imgIconePage{
  width: 60px;
  height: 60px;
  float: left;
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: 5px;
}
.nameIconePageHome{
  margin: 0 auto;
  text-align: center;
}
.nameIconePage{
  color: #7f32ee;
  margin-left: 172px;
  text-align: left;
}
.userName{
  width: 234.783px;
}
.typeName{
  margin-left: -35px;
  margin-top: -10px;
}

.headerItens{
  flex-direction: column;
  padding-top: 250px;
  margin-top: 50px;
}


/* ----------------------------------------------------------------------- */



/* Botões Tela Principal ------------------------------------------------------------------------------- */
.listAreas{
  flex-direction: row;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.listAreas2{
  flex-direction: row;
  display: inline-block;
  width: 90%;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.Areas{
  margin: 10px;
  background-color: transparent;
  color: #000;
  font-size: 8px !important;
  width: 190px;
  height: 150px;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  flex-direction: row;
  transition: 0.5s;
  cursor: pointer;
  list-style: none;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.15);
  text-decoration: none;
}
.Areas:hover{
  /* background-color: #8b13df; */
  transform: scale(1.07);
  box-shadow: 1px 1px 10px rgba(0,0,0,0.3);
}

.Areas > img {
  /* filter: brightness(60%); */
  width: 100%;
  height: 65%;
  opacity: 1;
  z-index: -1;
  background-size: 100%;
  box-shadow: 1px 5px 10px rgba(0,0,0,0.1);
  /* margin-top: 5px */
  object-fit: cover;
}
.Areas > p{
  color: #fff;
  margin-top: 10px;
  opacity: 1;
  z-index: 1;
  text-transform: uppercase;
  font-weight: bold;
}
.Areas > a{
  color: #000;
}

@keyframes img-spin {
  0% { transform: scale(1);}
  50% {transform: scale(1.1);}
  100% {transform: scale(1);}
}

.icon{
  height: 30%;
  margin-top: 20px;
  margin-bottom: -10px;
  align-items: flex-start;
}
.iconLab{
  height: 70%;
  margin-bottom: -15px;
}
.logoLab{
  height: 18px;
}
.Areas > h1 {
  margin-top:10px;
}

.AreasSerie{
  height: 100px;
  align-items: center;
  background-color: #5d009b;
}

/* ----------------------------------------------------------------------- */




/* Types Areas -------------------------------------------------------------------------------*/
.type1{
  width: 275px;
  height: 160px;
  font-size: 10px !important;
  color: #000;
  display: inline-block;
  transition: 0.4s;
  border-radius: none;
}
.typePodcast{
  width: 275px;
  height: 160px;
  font-size: 10px !important;
  color: #000;
  background-color: transparent;
  display: inline-block;
  transition: 0.4s;
}

.typePodcast > img{
  width: 100%;
  height: 100%;
}

.type1 > img{
  width: 100%;
  height: 100%;
}

.txtCard{
  display: none;
  text-align: center;
}

.type2{
  width: 15%;
  height: 230px;
  font-size: 15px !important;
  justify-items: center;
  background-color: #023b743a;
  color: #000;
  border-radius: 0px;
  display: inline-block;
  margin-right: 20px;
}
.type2:hover{
  transform: scale(1.017);
}
.type2 > img{
  width: 100%;
  height: 80%;
}
.type3{
  width: 200px;
  height: 290px;
  font-size: 25px !important;
  color:#000;
  font-weight: bold;
  display: inline-block;
}
.type3 > h1{
  margin: 15px !important;
  font-size: 30px;
  display: none;
}
.type3 > p{
  display: none;
}
.type3 > img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.type4{
  width: 150px;
  height: 60px;
  font-size: 10px !important;
  color: #000;
  margin-bottom: 100px;
  border-radius: 0px;
  margin-left: -10px;
  box-shadow: none;
}
.type4:hover{
  transform: scale(1);
  background-color: transparent;
  color: #fff !important;
}
.type4 > p{
  margin-top: 20px;
  font-size: 15px !important;
  font-weight: none !important;
}
.type4-1{
  border-radius: 5px 0px 0px 5px;
}
.type4-2{
  border-radius: 0px 5px 5px 0px;
}
.type5{
  width: 1060px;
  height: 350px;
  font-size: 20px !important;
  color: #7f32ee;
  margin-top: -80px;
}
.type5>img{
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.type6{
  width: 250px;
  height: 200px;
  font-size: 15px !important;
  justify-items: center;
  color: rgb(136, 136, 136);
  background-color: rgba(231, 231, 231, 0.3);
}
.type6 > img{
  height: 80%;
}

/* ----------------------------------------------------------------------- */



/* Itens -------------------------------------------------------------------------------*/
.item-header {
  margin-top: 310px;
  margin-bottom: 100px;
  font-size: 20px;
  min-height: 600px;
  display: block;
  flex-direction: row;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  justify-content: center;
  color: white;
  z-index: -1000;
}
.item-header-dashboard{
  margin-top: 310px;
  margin-bottom: 100px;
  font-size: 20px;
  min-height: 600px;
  display: block;
  flex-direction: row;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  justify-content: center;
  color: white;
  z-index: -1000;
}
.item-header-content{
  margin-top: 50px;
  margin-bottom: 100px;
  font-size: 20px;
  min-height: 300px;
  justify-content: left;
  color: white;
  z-index: -1000;
}
.btnFinanceiro{
  display: block;
  padding-bottom: 20px;
}
.btnFinanceiroIn{
  background-color: #7f32ee !important;
  margin-right: 10px;
  margin-top: 15px;
}
.boxFluxoCaixa{
  margin-top: 50px;
}

.btnFinanceiroIn:hover{
  background-color: #fff !important;
}
.fluxoCaixaUL{
  padding: 10px;
  margin-bottom: 20px;
}
.fluxCaixaLI{
  background-color: rgb(211, 211, 211);
  color: rgb(114, 114, 114);
  list-style: none;
  margin-top: 10px;
  display: inline-flex;
  width: 90%;
  border-radius: 10px;
}
.fluxCaixaLI > p{
  margin: 10px;
  margin-left: 40px;
  font-size: 15px;
  width: 25%;
  text-align: left;
}

.Item{
  width: 60%;
  height: 150px;
  margin: 10px;
  background-color: #ff5257;
  color: white;
  font-size: 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  border: 1px dashed white;
  padding-top: 30px;
  transition: 0.5s;
  font-size: 50%;
  cursor: pointer;
}

.Item:hover{
  background-color: #c2151a;
}
.Item:hover > .Item-img{
  transform: scale(1.1)
}

.Item-img{
  width: 30%;
  height: 150px;
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  contain: content;
  transition: 1s;
}
.Item-txt{
  width: 70%;
  height: 100px;
  text-align: justify;
  margin-top: 5px;
  float: left;
}
.txt-detals{
  margin-top: -10px;
}
.imgItem{
  border-radius: 10px;
  background-size: 100%;
  border: 2px dashed white;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.btn-detals{
  position: relative;
  left: 80px;
  margin-left: 10px;
  width: 70px;
  background-color: #ff5257;
  color: white; 
  border-radius: 10px;
  border: 1px dashed white;
  padding: 10px;
  transition: 0.5s;
  font-size: 12px;
  cursor: pointer;
}
.btn-detals:hover{
  background-color: #c2151a;
  transform: scale(1.07);
  animation: none;
}

.download{
  width: 200px;
  height: 200px;
  background-color: white;
  margin: 0 auto;
  border-radius: 10px;
  color: silver;
  align-items: flex-end;
  justify-content: flex-end;
  align-content: flex-end;
  display: none;
}

/* ----------------------------------------------------------------------- */





/* Gráficos  -------------------------------------------------------------------------------*/
.Grafich{
  margin-top: 100px;
  align-items: flex-end;
  justify-content: flex-end;
  align-content: flex-end;
  display: inline-flex;
}
.barrasSection{
  margin: 20px;
  width: 14.28%;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
.barra{
  width: 50px;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 10px 10px 0px 0px;
}


/* ----------------------------------------------------------------------- */



/* Caixa de list-itens -------------------------------------------------------------------------------*/
.box-list{
  margin-top: 230px;
  margin-bottom: 50px;
  width: 100%;
  flex: 1;
  /* background-color: #9569D5; */
  align-items: center;
  border-radius: 40px;
}
.search{
  display: block;
  width: 100%;
  margin-top: 250px;
  padding-top: 20px;
  border-radius: 40px;
  align-items: center;
}
.filterSearch{
  margin-bottom: -10px;
}
.searchBox{
  box-shadow: none;
  margin: 0 auto;
  border-radius: 20px;
  width: 65%;
  height: 130px;
  justify-items: center;
  align-content: flex-end;
  padding-top: 2px;
  margin-top: -50px;
}

.vencimentoCard > input{
  width: 40%;
  margin-right: 10px;
}



/* Manager Inputs */
.input-login{
  width: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 30px;
  font-size: 15px;
  background-color: transparent;
  border-top: 0px solid;
  border-left: 0px solid;
  border-right: 0px solid;
  border: 1px solid #bbb6b6;
  color: #707070;
  padding-left: 10px;
}
.input-login::-webkit-inner-spin-button{
  -webkit-appearance: none;
}
.input-login:focus{
  border-top: 0px solid;
  border-left: 0px solid;
  border-right: 0px solid;
  border-bottom: 1px solid #FF6200;
}
.input-vencimento{
  margin-left: 15px;
}
.input-small{
  width: 10%;
}
.input-medium{
  width: 36%;
  margin-left: 2%;
  margin-right: 2%;
}
.input-medium-correct{
  margin-left: 0 !important;
}
.input-card{
  width: 18%;
  margin-left: 2%;
}
.selectInput{
  border-radius: 0px !important;
}
.itemBoxInsert{
  margin-top: -900px;
  height: auto;
  flex: 1;
  width: 85%;
}
.loginBox{
  width: 40% !important;
  height: 455px;
  flex: 1;
  width: 85%;
  border-radius: 5px;
}
.singupBox{
  height: 555px;
}
.paymentBox{
  height: 1055px;
}
.singupBox > form > h2{
  font-weight: 400;
}
.modifyBox{
  width: 40%;
  height: 200px;
  margin: 200px 0 250px 30%;
}
.title-msg-atualizar-pagamento{
  font-size: 35px;
  font-weight: 400;
}
.desc-msg-atualizar-pagamento{
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 60px;
}

.div-label{
  margin-left: 50px;
  text-align: left;
}
.div-label > label{
  color: #CE4F00;
  text-decoration: underline;
}
.div-label-modify > label{
  color: #CE4F00;
  text-decoration: underline;
}


.loginBox>p{
  color: white !important;
}
.mensalBoxInsert{
  margin-top: 100px;
  height: auto;
  flex: 1;
  width: 85%;
}

.forgotBox{
  height: 450px;
}


.destaque2{
  width: 100%;
  background-color: red;
}
.destaque2-left{
  width: 50%;
  float: left;
}
.banner-destaque2{
  width: 100%;
}
.destaque2-rigth{
  width: 50%;
  float: right;
}
.logo-destaque2{
  width: 70%;
}
.txtDestaque2{
  font-size: 20px;
}
.btnDestaque2{
  font-size: 15px;
  padding: 10px;
  background-color: #363636;
  color: #fff;
  border: none;
  border-radius: 5px;
}


.boxAdmin{
  margin-top: 350px;
}
.corBox{
  background-color: #cccccc57;
}
.descripton{
  height: 200px !important;
  border-radius: 20px !important;
}
.itemDetals{
  align-content: flex-start;
  align-items: flex-start;
}
.itemDesc{
  width: 80% !important; 
  align-content: flex-start;
}
.inputItem{
  margin-bottom: 10px;
}
.detalsInputs{
  align-items: flex-start;
  align-content: flex-start;
  display: inline-flex;
  flex-direction: row;
}
.imageBox{
  width: 45%; 
  height: 200px;
  padding: 10px;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 10px;
}
.inputFile{
  background-color: transparent !important;
  width: 85% !important; 
  height: 90px !important;
  border-radius: 20px !important;
  border: 3px dashed silver !important;
  box-shadow: none !important;
  margin-bottom: 50px;
  cursor: pointer;
}
.inputFile:focus{
  box-shadow: none !important;
  border: 3px dashed #7f32ee !important;
}
.btnBox{
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btnLogin{
  font-weight: 700;
  width: 25%;
  height: 35px !important;
  margin: 5px 7px 20px 7px;
  background: #FF5000;
  border: none;
  border-radius: 1px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  transition: 0.4s all;
}
.btnPay{
  width: 53% !important;
}

.btnCadastro{
  background-color: transparent;
  border: 1px solid #FF6200;
}

.btnLogin:hover{
  color: #fff;
  background-color: #A54102;
}
.btnLoginTxt{
  color: #4e91ff;
  font-size: 12px;
  cursor: pointer;
  transition: 0.4s all;
}
.btnLoginTxt:hover{
  color: #fff;
}

.btnLoginSM{
  width: 64%;
  height: 45px !important;
  margin-bottom: 10px;
  background-color: transparent;
  border: 1px solid #898989;
  border-radius: 7px;
  color: #898989;
  font-size: 12px;
  cursor: pointer;
  transition: 0.4s all;
}
.btnLoginSM:hover{
  border: 1px solid #fff;
  color: #fff;
}

.btnSend{
  width: 33%; 
  height: 45px;
  flex: 1;
  margin-bottom: 50px;
  border-radius: 10px;
  background-color: #9198a0;
  color: white;
  font-size: 20px;
  border: 1px solid #9198a0;
  transition: 0.3s;
  cursor: pointer;
}
.btnSend:hover{
  background-color: white;
  color: #7f32ee;
  border: 1px solid #7f32ee;
}

/* Search Inputs */
.searchText{
  width: 95% !important;
  padding: 40px;
  padding-left: 100px;
  font-size: 20px;
  color: #9A9A9A;
  background-color: #262626;
  border: none;
  margin-top: 120px;
  border-radius: none !important;
  box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.048);
}
.searchTitle{
  font-size: 20px;
  color: #7f32ee;
  text-align: left;
  margin-left: 35%;

}

.iten-list{
  display: inline-block;
  height: 200px;
  width: 18%;
  margin-right: 10px;
  margin-top: 10px;
  color: white;
  list-style: none;
  cursor: pointer;
  border-radius: 40px;
  background-color: rgba(133, 86, 204, 0.3);
  transition: 0.4s;
  align-items: center;
  justify-content: center;
}
.iten-list-img{
  width: 100%;
  height: 100%;
  border-radius: 40px;
  margin-bottom: -50px;
}
.iten-list:hover{
  background-color: rgb(133, 86, 204);
  transform: scale(1.02);
  box-shadow: rgba(0,0,0,0.3) 10px 10px 20px;
}


.listBox{
  margin-top: -250px;
  margin-left: -20px;
}

.iten-list-content{
  display: inline-block;
  width: 150px;
  height: 230px;
  color: rgb(136, 136, 136);
  background-color: rgba(231, 231, 231, 0.6);
  list-style: none;
  cursor: pointer;
  border-radius: 20px;
  transition: 0.4s;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-top: 20px;
}
.iten-list-content:hover{
  transform: scale(1.01);
  box-shadow: 1px 1px 10px rgba(0,0,0,0.3);
}
.iconContent{
  width: 100%;
  height: 55%;
  border-radius: 20px 20px 0px 0px;
}
.txtContent{
  font-size: 18px;
  flex: 1;
  align-items: flex-end;
  text-align: center;
}
.txtContent-title{
  margin-top: 1px;
  font-size: 10px;
  font-weight: bold;
}
.txtContentDetls{
  font-size: 10px;
  margin-top: -10px;
  font-weight: bold;
}
.txtContentDetls-ap{
  font-size: 25px;
  margin-top: -17px;
  font-weight: bold;
}

.mensage{
  width: 80%;
  height: 200px;
  border-radius: 5px;
  border: 1px solid silver;
  padding: 5px;
}
.mensage:focus{
  border: 1px solid #7f32ee;
}
.mensageBtn{
  width: 81%;
  height: 40px;
  margin-top: 20px;
  background-color: #7f32ee;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: white;
  transition: all 0.4s;
}
.mensageBtn:hover{
  background-color: #5a1daf;
}

.visitanteInput{
  width: 13%;
  height: 30px;
  margin: 5px;
  margin-top: 30px;
  border-radius: 5px;
  border: 1px solid silver;
  padding: 5px;
}
.visitanteInput:focus{
  border: 1px solid #7f32ee;
}
.visitanteBtn{
  width: 11%;
  height: 40px;
  margin: 5px;
  margin-top: 20px;
  background-color: #7f32ee;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: white;
  transition: all 0.4s;
}
.btnHidden{
  display: none;
}
.visitanteBtn:hover{
  background-color: #5a1daf;
}


/* ----------------------------------------------------------------------- */





/* Comentários - Ouvidoria -------------------------------------------------------------------------------*/
.comments{
  padding: 10px;
}
.comment{
  background-color: rgba(192, 192, 192, 0.342);
  color: rgb(85, 85, 85);
  list-style: none;
  flex: 1;
  width: 80%;
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 0px 10px 10px 10px;
  font-size: 15px;
  text-align: left;

}
.comment > h1{
  margin-bottom: -20px;
  margin-top: -10px;
}

/* ----------------------------------------------------------------------- */





/* Formulários -------------------------------------------------------------------------------*/
.form{
  display: block;
  flex-direction: grid;
  align-content: flex-start;
  margin-top: 300px;
  margin-bottom: -250px;
}

.formComent{
  margin-top: 200px;
  margin-bottom: 50px;
  flex-direction: column;
  align-content: flex-start;
  display: inline-block;
  width: 70%;
}


.inputStyle{
  border-radius: 10px !important;
  border: 1px solid silver;
  padding: 5px;
  padding-left: 20px;
  transition: 0.3s;
  border-radius: 60px;
  color:silver;
  background-color: #fff;
  width: 70%;
  height: 30px;
  font-size: 15px;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
}

.inputText{
  color:silver;
  background-color: #fff;
  width: 30%;
  height: 30px;
}
.inputSelect{
  height: 40px;
}
.inputSelect:focus{
  box-shadow: 2px 2px 20px rgba(0,0,0,0.1);
  color: #fff;
}

.inputBig{
  width: 92%;
}
.inputMid{
  width: 35%;
}
.inputSmall{
  width: 30%;
}

.inputText:focus{
  box-shadow: 5px 8px 8px rgba(0,0,0,0.3);
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;
  color: #6e6e6e;
  border: 1px solid #7f32ee;
}

.inputButton{
  height: 40px;
  border: 2px solid white;
  margin: 0 auto;
  margin-left: 10px;
  margin-top: 10px;
  color: white;
  transition: 0.4s;
  cursor: pointer;
}
.inputButton:hover{
  transform: scale(1.07);
  animation: none;
}

.btnLimpar{
  background-color: transparent;
}
.btnEnviar{
  background-color: #FF9800;
  border: none;
}
.titleLogin{
  width: 53%;
  font-weight: 600;
  text-align: left;
  margin-left: 23%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #bababa;
}
.txtErro{
  color: rgb(201, 0, 0) !important;
  width: 53%;
  font-weight: 600;
  text-align: left;
  margin-left: 23%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #bababa;
}

.paymentInfos{
  width: 100%;
  margin-left: -30px;
  padding-top: 10px;
  margin: 20px auto;
  width: 90%;
}
.paymentInfos > h1{
  font-weight: 600;
  font-size: 15px;
  color: #fff !important;
  margin-left: 10%;
  margin-right: 10%;
  display: inline;
}
.paymentInfos > h2{
  font-weight: 400;
  font-size: 20px;
  color: #ff5000 !important;
  margin-left: 10%;
  margin-right: 10%;
  display: inline;
}

.paymentInfos > p{
  font-size: 10px;
  color: rgb(170, 170, 170) !important;
  margin-top: -3px;
  margin-bottom: 20px;
}


/* ----------------------------------------------------------------------- */




/* Lista de Itens -------------------------------------------------------------------------------*/

.box-Item{
  margin-top: 10px;
  margin-bottom: 50px;
  width: 80%;
  padding: 50px;
  border-radius: 10px;
  display: block;
  flex-direction: column;
  align-items: center;
  clear: both;
  flex: 1;
  color: #fff;
}
.boxItem-text{
  margin-top: 100px;
  width: 40%;
  border-radius: 40px;
  text-align: left;
}


.box-ItemForumAsk{
  margin-top: 200px;
  background-color: #9569D5;
  width: 90%;
  height: auto;
  padding: 30px;
  border-radius: 40px;
  display: block;
  flex-direction: column;
  clear: both;
}
.box-ItemForumComent{
  margin-top: 20px;
  background-color: #9569D5;
  width: 90%;
  height: auto;
  padding: 30px;
  border-radius: 40px;
  display: block;
  flex-direction: column;
  clear: both;
}


/* ----------------------------------------------------------------------- */



/* Videos ------------------------------------------------------------------------------- */

.headerItem {
  width: 50%;
  flex: 1;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  float: left;
}
.imgItem{
  width: 90%;
  height: 400px;
  border: none;
}
.titleItem{
  font-size: 45px;
  color: #7f32ee;
  margin-top: -100px;
}
.titleLocal{
  line-height: 0.9;
  margin-bottom: 50px;
}
.txtItem{
  font-size: 15px;
  width: 102%;
  text-align: center;
}
.txtBigItem{
  text-align: justify !important;
}
.boxBtns{
  display: inline-block;
}
.btnItem{
  margin-right: 10px;
  width: 150px;
  background-color: rgb(95, 43, 173);
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}
.service{
  width: 100%;
  margin-top: -30px;
  color: #fff;
  clear: both;
  text-align: left;
  justify-items: left;
  align-items: flex-start;
  align-content: flex-start;
  display: inline-flex;
  flex-direction: row;
}
.service2{
  width: 20%;
  clear: both;
  text-align: left;
  font-size: 15px;
}
.service3{
  width: 35%;
  height: 300px;
  margin-left: 50px;
  background-color: #9e9e9e;
  border-radius: 20px;
}
.titleService{
  font-size: 25px;
  margin-left: 25px;
}


/* ----------------------------------------------------------------------- */




/* Forum itens -------------------------------------------------------------------------------*/

.titleforumItem{
  float: left;
  margin-right: -100px;
  font-size: 15px;
  margin-left: 30px;
}

.txtforumItem{
  font-size: 25px;
  width: 40%;
  text-align: justify;
  margin-right: 10px;
  margin-top: 50px;
  width: 100%;
}

.ComentsBox{
  width: 500px;
}
.inputComent{
  width: 420px;
  height: 140px;
  padding: 18px;
}


/* Caixa de Filtros */
.boxFilter{
  display: inline;
  background:#ff5257;
  border: dashed white;
  border-width: 2px 2px 2px 0px;
  box-shadow: 7px 7px 15px rgba(0,0,0,0.3); 
  border-radius: 5px;
}

.boxFilter-btn{
  width: 100px;
  height: 35px;
  background: transparent;
  border: none;
  border-radius: 5px;
  color: white;
  transition: 0.3s;
  border-left: 2px dashed white;
}

.boxFilter-btn:focus{
  background: #c2151a;
}

/* ----------------------------------------------------------------------- */



/* Lista-------------------------------------------------------------------------------*/
.list{
  border: 2px dashed white;
  background: #c2151a;
}
.listItem{
  text-align: center;
  font-size: 15px;
  width: 870px;
  height: 50px;
  list-style: none;
}
.firstTxtList{
  margin-left: 0px;
  float: left;
}
.txtList{
  float: left;
  margin-left: 140px; 
}

/* ----------------------------------------------------------------------- */




/* Notas ------------------------------------------------------------------------------------- */

.AreasMateria{
  width: 140px;
  height: 105px;
  background: #9569D5;
  border: 10px solid white;
}
.AreasMateria > h1{
  margin-top: 30px;
}

.listaNotas{
  width: 100%;
  margin-left: 10px;
}
.listaParciais{
  float: left;
}
.listaGlobais{
  float: right;
}
.notaArea{
  display: inline-block;
  list-style: none;
  padding: 28px;
  width: 45px;
  margin: 10px;
  border-radius: 50%;
  background: #9569D5;
  font-size: 30px;
  font-weight: bold;
}
.medias{
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 70px;
  flex-direction: column;
  align-items: center;
  list-style: none;
  clear: both;
  position: absolute;
  top: 320px;
}
.medias > li{
  display: inline-block;
  margin: 10px;
  border-radius: 50%;
  padding: 25px;
  width: 98px;
  height: 98px;
  text-align: center;
  background: #9569D5;
  font-size: 20px;
}
.medias > li > p{
  font-size: 15px;
}

#mediaParcial{
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px
}
#mediaGlobal{
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px
}
#mediaTotal{
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px
}
.materiaName{
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 40px;
  padding: 10px;
  background: #9569d5;
  width: 150px;
  border-radius: 10px;
}


/* Calendário ------------------------------------------------------------------------------- */
.dateList{
  flex-direction: column;
  align-items: center;
  width: 71.5%;
  margin: 0 auto;
  padding: 10px;
  margin-top: -200px;
}
.date{
  margin: 10px;
  float: left;
  width: 250px;
  height: 100px;
  padding: 20px;
  background: #6e6e6e;
  cursor: pointer;
  transition: 0.3s;
}
.date:hover{
  transform: scale(1.1)
}
.dateDay{
  margin: 0;
}



/* ----------------------------------------------------------------------- */




/* Lista de horários -------------------------------------------------------------------------------*/
.horaFuncTxt{
  float: left;
  margin-left: 60px; 
}



/* ----------------------------------------------------------------------- */



/* Tamanhos -------------------------------------------------------------------------------*/
.large{
  width: 250px;
}
.medio{
  width: 152px;
}
.menor{
  width: 80px;
}



/* ----------------------------------------------------------------------- */




/* Globais -------------------------------------------------------------------------------*/
.center{
  margin: 0 auto;
}

/* ----------------------------------------------------------------------- */




/* Itens Unicos -------------------------------------------------------------------------------*/
.cod{
  width: 110px;
  height: 18px;
  padding: 5px;
  color: #fff;
  background-color: transparent;
  margin: 0 auto;
  border-radius: 5px;
  border: 0.2px dashed rgba(255,255,255,0.6);
}


/* ----------------------------------------------------------------------- */




/* Rodapé -------------------------------------------------------------------------------*/
.footerSection{
  width: 30px;
  background-color: #000;
  height: 50px;
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  padding-left: 10%;
  align-items: center;
  align-content: center;
  justify-content: center;
  list-style: none;
}
.footer-logo{
  margin-top: -100px;
  width: 30px;
  background-color: #000;
  height: 50px;
  width: 100%;
  margin-bottom: -20px;
  margin-left: -10px;
  padding-top: 5px;
}
.logoFooter{
  margin-top: 15px;
  margin-bottom: -15px;
  width: 60px;
}
.logoFooter-City{
  width: 200px;
  margin-top: 30px;
  margin-left: 20%;
}
.footerSeparator{
  margin-top: 50px;
}
.footer{
  background-color: #000;
  flex: 1;
  width: 103%;
  margin-top: 200px;
  margin-left: -10px;
  font-size: 13px;
  color: #a7a7a7;
}
footer > div > p > a {
  color: #ff5000;
}

/* Mobile  ----------------------------------------------------------------------- */

@media (max-width: 900px){
  body{
    overflow-x: none !important;
    width: 100%;
  }
  .backgroundHero{
    height: 700px;
  }
  .App-header{
    flex-direction: column;
    margin-top: 100px;
    margin: 0 auto;
    text-align: center;
  }
  .App-logo{
    top: 10px;
  }
  .logo-menu{
    top: 10px;
    margin-left: 17%;
  }
  .left-menu{
    display: none;
  }
  .Areas:hover{
    transform: scale(1);
    box-shadow: 1px 1px rgba(0,0,0,0.1);
  }
  .backgroundHero{
    width: 110%;
    z-index: -3;
    object-fit: cover;
  }
  .logo-curso-destaque{
    margin-top: -150px;
    width: 80%;
  }
  .title-curso-destaque{
    font-size: 30px;
    line-height: 47px;
    width: 80%;
    left: 0px;
  }
  .desc-curso-destaque{
    width: 80%;
  }
  .btn-curso-destaque{
    width: 50%;
  }
  .curso-destaque{
    height: 300px;
    z-index: 1;
    margin-left: -30px;
  }
  .type1{
    width: 100%;
    margin-left: 40px;
  }
  .typePodcast{
    width: 99%;
    margin-left: 50px;
  }
  .courses{
    margin-top: -200px;
    width: 100%;
    margin-left: -60px;
  }
  .backgroundLaunch{
    width: 90%;
    margin-left: -20px;
    height: 417px;
    width: 110%;
  }
  .watchVideo{
    width: 100% !important;
    height: 200px !important;
    margin-left: -50px;
    margin-top: -40px;
  }
  .title-curso-lancamento{
    display: block;
  }
  .desc-curso-lancamento{
    display: block;
    width: 60%;
  }
  .btn-curso-lancamento{
    width: 40%;
  }
  .divTitleSection{
    margin-left: 40px;
  }

  .backgroundLaunch>p>img{
    opacity: 0;
  }
  
  .videoLiveInicio {
    width: 100%;
    height: 360px;
  }
  
  /* .videoLiveInicio > div > div > iframe {
    width: 55%;
    height: 90%;
    margin-top: 100px;
  } */





  .box-class{
    display: block;
  }
  .boxClass-text{
    width: 70%;
    margin-top: 20px;
  }
  .class{
    margin-left: -70px;
  }
  .class > img{
    width: 250px;
  }
  .profile-items{
    top: 20px;
    left: 80%;
    display: none;
  }
  .btn-dash{
    margin-left: 35px !important;
    width: 100%;
  }
  .input-medium-correct{
    margin-left: 25px !important;
  }
  .box-video-aula{
    display: inline;
    width: 95%;
    margin-left: -20px;
  }
  .box-video-aula > h1{
    margin-left: 10px;
  }
  .playVideoWatch{
    width: 100% !important;
  }
  .attr-video{
    display: block;
  }
  .desc-video{
    width: 95%;
  }
  .downloads-video{
    width: 100%;
    text-align: center;
  }
  .infos {
    display: block;
    width: 100%;
    align-items: center;
    margin-bottom: 189px;
  }
  .left-infos{
    margin-left: 20px;
    width: 80%;
  }
  .rigth-infos{
    margin-left: 42px;
    width: 80%;
    margin-bottom: 100px ;
  }
  .btnDash {
    width: 40% !important;
    margin-left: 0px !important;
  }
  .box-video-aula > div{
    height: 215px !important;
    width: 80%;
    margin-top: 180px;
  }
  .input-login{
    width: 80%;
    margin-left: -70px;
  }
  .title-modify{
    margin-left: 20px;
  }
  .modifyBox {
    width: 70%;
    height: 238px;
  }
  .modifyBox{
    margin-left: 20px;
  }
  .btnBox{
    width: 100%;
    margin-left: -20px !important;
  }
  .div-label-modify{
    margin-left: -70px;
  }
  .titleLogin{
    width: 80%;
    margin-left: 25px;
  }
  .btnBox{
    width: 100%;
    align-items: center;
  }
  .btnBox {
    display: block;
  }
  .btnLogin{
    margin-left: -30px;
    width: 85%;
  }
  .loginBox{
    margin-left: -10px;
    width: 95%;
    margin-bottom: 150px;
  }
  .loginBox> form{
    margin-left: 50px;
  }
  .input-dash{
    width: 85% !important;
    margin-left: 10%;
  }
  .btn-dash{
    margin-left: 55px;
  }
  .btnLoginTxt{
    width: 100%;
    margin-left: -40px;
    text-align: center;
  }
  .forgotPass{
    margin-left: -70px;
  }
  .singupBox{
    height: 650px;
  }
  .politicPrivacy{
    width: 100%;
    display: block;
    margin-left: -30px;
    text-align: center;
    font-size: 6px !important;
    color: #707070;
  }
  #politic-privacy{
    font-size: 15px !important;
    color: #707070 !important;
  }
  .a-politic-privacy{
    margin-left: 10px;
    color: #A54102;
  }
  .paymentBox{
    height: 1100px;
  }
  .div-label{
    margin-left: -10px;
  }
  .title-form-labels{
    margin-left: -50px;
  }

  .type1:hover{
    transform: scale(1);
    box-shadow: 1px 1px rgba(0,0,0,0.1);
  }
  .type4{
    display: none;
    font-size: 8px !important;
  }
  .type4-1{
    display: block;
    border-radius: 10px 0px 0px 10px;
    font-size: 8px !important;
    margin-left: -12%;
  }
  .type4-2{
    display: block;
    border-radius: 0px 10px 10px 0px;
    font-size: 8px !important;
  }
  .type4:hover{
    font-size: '';
  }
  .type2{
    width: 40%;
    height: 30%; 
  }
  .listAreas2{
    margin:0 auto;
    margin-left: -15%;
  }
  .Grafich{
    margin-left: -70%;
  }
  .barrasSection{
    width: 2%;
    font-size: 5px;
  }
  .barra{
    width: 100%;
  }
  .ficha-tecnica {
    display: block;
  }
  .ficha-tecnica-left {
    width: 62%;    
    margin-left: 20%;
  }
  .titleFichaTecnica {
    left: -69px;
  }
  .underline {
    left: -66px;
  }
  .visitanteInput{
    width: 80%;
    margin-left: -10%;
  }
  .visitanteBtn{
    width: 80%;
    margin-left: -10%;
  }
  #ouvidoria > form{
    margin-left: -10%;
  }
  .btnSend{

    width: 90%;
  }
  .footer{
    width: 112%;
  }
  .footer-logo {
    height: 100px;
    width: 76%;
    margin-left: 30px;
  }
  .loginBox{
    width: 95% !important;
  }  
}